// Vue.js
if(window.Vue === undefined)
{
    window.Vue = require('vue');
}

import SearchComponent from './Components/SearchComponent'

const app = new Vue({
    el: '#search',
    components: {
        SearchComponent
    }
});
