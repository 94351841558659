<template>
    <div class="search-container" :class="{ 'show-advanced-search-fields' : showAdvancedSearch, 'show-advanced-search-fields-small' : filter.property_type_id.name === 'Garaje' }">

        <div class="row">
            <div class="col-lg col-md-6">

                <div class="form-group">

                    <label>Tip tranzacție</label>

                    <v-select :options="transactionTypes"
                              v-model="filter.transaction_type_id"
                              :searchable=false
                              :clearable=false
                              :label="'name'"
                              placeholder="Alege tip tranzacție">
                    </v-select>

                </div><!-- form-group -->

            </div><!-- col -->
            <div class="col-lg col-md-6">

                <div class="form-group">

                    <label>Tip proprietate</label>

                    <v-select :options="propertyTypes"
                              v-model="filter.property_type_id"
                              :searchable=false
                              :clearable=false
                              :label="'name'"
                              @input="getCities"
                              placeholder="Alege tip proprietate">
                    </v-select>

                </div><!-- form-group -->

            </div><!-- col -->
            <div class="col-lg col-md-6">

                <div class="form-group">

                    <label>Localitate</label>

                    <v-select :options="cities"
                              v-model="filter.city_id"
                              :searchable=false
                              :clearable=false
                              :label="'name'"
                              @input="getZones"
                              :disabled="cities.length === 0"
                              placeholder="Selectează orașul">
                    </v-select>

                </div><!-- form-group -->

            </div><!-- col -->
            <div class="col-lg col-md-6">

                <div class="form-group">

                    <label class="d-none d-md-block">&nbsp;</label>

                    <button class="btn btn-primary btn-lg w-100"
                            @click="searchOffers"
                            :disabled="loading"
                            type="button">Caută</button>

                </div><!-- form-group -->

            </div><!-- col -->
        </div><!-- row -->

        <div class="row">
            <div class="col-12">

                <a class="advanced-search" href="#" @click.prevent="showAdvancedSearch = !showAdvancedSearch">
                    <span v-if="!showAdvancedSearch">Căutare avansată</span>
                    <span v-else>Ascunde căutarea avansată</span>
                    <i class="icon-arrow-right"></i>
                </a>

            </div><!-- col -->
        </div><!-- row -->

        <div class="advanced-search-fields">

            <div class="row">
                <div class="col-lg col-md-6">

                    <div class="form-group">

                        <label>Cartier</label>

                        <v-select :options="zones"
                                  v-model="filter.zone"
                                  :searchable=false
                                  :clearable=false
                                  :label="'name'"
                                  @input="getStreets"
                                  :disabled="filter.city_id === undefined"
                                  placeholder="Selectează cartierul">
                        </v-select>

                    </div><!-- form-group -->

                </div><!-- col -->
                <div class="col-lg col-md-6">

                    <div class="form-group">

                    <label>Zona</label>

                    <v-select :options="streets"
                              multiple
                              v-model="filter.street_id"
                              :searchable=false
                              :clearable=false
                              :label="'name'"
                              :disabled="filter.zone === undefined"
                              placeholder="Selectează zona">
                    </v-select>

                    </div><!-- form-group -->

                </div><!-- col -->
                <div class="col-lg col-md-12" v-if="filter.prices.length > 0">

                    <div class="form-group">

                        <label>Preț</label>

                        <span class="price-interval">Interval preț selectat: {{ filter.prices[0] }} - {{ filter.prices[1] }} &euro;</span>

                        <vue-slider v-model="filter.prices"
                                    :max="prices[1]"
                                    :min="prices[0]"
                                    :height="5"
                                    :interval="10"
                                    tooltip="none">
                        </vue-slider>

                    </div><!-- form-group -->

                </div><!-- col -->
            </div><!-- row -->

            <div class="row mt-4">

<!--                Apartamente-->
                <div class="col-lg-6 col-md-12" v-if="filter.property_type_id && [2].includes(filter.property_type_id.id)">
                    <label>Număr camere</label>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <input type="text"
                                       v-model="filter.rooms_min.id"
                                       placeholder="Min.">
                            </div><!-- form-group -->
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <input type="text"
                                       v-model="filter.rooms_max.id"
                                       placeholder="Max.">
                            </div><!-- form-group -->
                        </div>
                    </div>
                </div><!-- col -->

<!--                Case, Vile-->
                <div class="col-lg col-md-12" v-if="filter.property_type_id && [58].includes(filter.property_type_id.id)">
                    <div class="row">
                        <div class="col-md">
                            <label>Număr camere</label>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <input type="text"
                                               v-model="filter.rooms_min.id"
                                               placeholder="Min.">
                                    </div><!-- form-group -->
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <input type="text"
                                               v-model="filter.rooms_max.id"
                                               placeholder="Max.">
                                    </div><!-- form-group -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md">
                            <label>Suprafață casă/vilă (mp)</label>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <input type="text"
                                               v-model="filter.surface_min.id"
                                               placeholder="Min.">
                                    </div><!-- form-group -->
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <input type="text"
                                               v-model="filter.surface_max.id"
                                               placeholder="Max.">
                                    </div><!-- form-group -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- col -->
<!--                Spatii comerciale -->
                <div class="col-lg-6 col-md-12" v-if="filter.property_type_id && [3].includes(filter.property_type_id.id)">
                    <label>Suprafață utilă (mp)</label>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <input type="text"
                                       v-model="filter.surface_min.id"
                                       placeholder="Min.">
                            </div><!-- form-group -->
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <input type="text"
                                       v-model="filter.surface_max.id"
                                       placeholder="Max.">
                            </div><!-- form-group -->
                        </div>
                    </div>
                </div><!-- col -->

<!--                Terenuri-->
                <div class="col-lg-6 col-md-12" v-if="filter.property_type_id && [1].includes(filter.property_type_id.id)">
                    <label>Suprafață utilă (mp)</label>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <input type="text"
                                       v-model="filter.surface_min.id"
                                       placeholder="Min.">
                            </div><!-- form-group -->
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <input type="text"
                                       v-model="filter.surface_max.id"
                                       placeholder="Max.">
                            </div><!-- form-group -->
                        </div>
                    </div>
                </div><!-- col -->
            </div>

        </div><!-- advanced-search-fields -->

    </div>
</template>

<script>

    import vSelect from 'vue-select';
    import VueSlider from 'vue-slider-component';

    export default {

        components: {
            vSelect,
            VueSlider
        },

        data() {
            return {
                loading: false,
                showAdvancedSearch: false,
                filter: {
                    property_type_id: {},
                    prices: [],
                    rooms_min: {},
                    rooms_max: {},
                    surface_min: {},
                    surface_max: {},
                },
                transactionTypes: [],
                propertyTypes: [],
                cities: [],
                zones: [],
                streets: [],
                prices: [],
                rooms: [],
            };
        },

        mounted()
        {
            this.getTransactionTypes();
        },

        methods:
        {
            getTransactionTypes()
            {
                axios.get('/transaction-types')
                    .then(data => {
                        this.transactionTypes = data.data.data;
                        this.filter.transaction_type_id = this.transactionTypes[0];
                        this.getPropertyTypes();
                    })
                    .catch();
            },

            getPropertyTypes()
            {
                axios.get('/property-types')
                    .then(data => {
                        this.propertyTypes = data.data.data;
                        this.filter.property_type_id = this.propertyTypes[0];
                        this.getCities();
                    })
                    .catch();
            },

            getCities()
            {
                axios.get(`/cities?transaction_type_id=${this.filter.transaction_type_id.id}&property_type_id=${this.filter.property_type_id.id}`)
                    .then(data => {
                        this.cities = data.data.data;
                        this.filter.city_id = this.cities[0];
                        this.getZones();
                    })
                    .catch();
            },

            getZones()
            {
                axios.get(`/cities/${this.filter.city_id.id}/zones`)
                    .then(data => {
                        this.zones = data.data.data;
                        this.getPrices();
                    })
                    .catch();
            },

            getStreets()
            {
                axios.get(`/cities/${this.filter.city_id.id}/zones/${this.filter.zone.id}`)
                    .then(data => {
                        this.streets = data.data.data;
                    })
                    .catch();
            },

            getPrices()
            {
                const transactionType = this.filter.transaction_type_id !== undefined ? this.filter.transaction_type_id.id : '';
                const propertyType = this.filter.property_type_id !== undefined ? this.filter.property_type_id.id : '';
                axios.get(`/prices?transaction_type_id=${transactionType}&property_type_id=${propertyType}`)
                    .then(data => {
                        this.prices = data.data;
                        this.filter.prices = this.prices;
                    })
                    .catch();
            },

            searchOffers()
            {
                this.loading = true;
                axios.post('/cautare', this.filter)
                    .then(data => {
                        this.loading = false;
                        location.href = data.data;
                    })
                    .catch(() => this.loading = false);
            }
        }
    };

</script>
